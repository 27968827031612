<template>
  <SelectPanel :empty="filteredItems.length === 0">
    <template #search>
      <SelectPanelInput v-model="searchQuery" />
    </template>

    <template #items>
      <SelectPanelItem
        v-for="item in filteredItems"
        :key="item.id"
        :modelValue="isItemSelected(item.id)"
        :disabled="isItemDisabled(item.id)"
        :chip="item.id"
        :searchQuery="searchQuery"
        :label="item.name"
        @update:modelValue="handleListItemSelected(item)"
      />
    </template>

    <template #selectedItemsTitle>
      {{ selectedItemsLabel }}
    </template>

    <template v-if="selectedListItemsReverse.length > 0" #selectedItems>
      <SelectPanelSelectedItem
        v-for="item in selectedListItemsReverse"
        :key="item.id"
        :label="item.name"
        @remove="emits('removeSelectedItem', item)"
      />
    </template>
  </SelectPanel>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { SelectPanel, SelectPanelInput, SelectPanelItem, SelectPanelSelectedItem } from '@lasso/luikit'
import { objFromEntries } from '@lasso/shared/utils'

import { OptimizationAudience } from './types'

const props = withDefaults(defineProps<{
  listItems: OptimizationAudience[]
  selectedListItems: OptimizationAudience[]
  disableNotSelectedItems?: boolean
  selectedItemsLabel?: string
}>(), {
  selectedItemsLabel: 'Selected Items',
  disableNotSelectedItems: false,
})

const emits = defineEmits<{
  listItemSelected: [OptimizationAudience]
  removeSelectedItem: [OptimizationAudience]
}>()

const searchQuery = ref('')

const filteredItems = computed(() => {
  const query = searchQuery.value.trim().toLowerCase()

  return props.listItems.filter((item) => {
    return String(item.id) === query || item.name.toLowerCase().includes(query)
  })
})

const selectedListItemsReverse = computed(() => {
  return props.selectedListItems.slice().reverse()
})

const selectedItemsMap = computed(() => {
  return objFromEntries(
    props.selectedListItems.map(item => [item.id, item] as const),
  )
})

const isItemSelected = (id: number) => {
  return Boolean(selectedItemsMap.value[id])
}

const isItemDisabled = (id: number) => {
  return props.disableNotSelectedItems && !isItemSelected(id)
}

const handleListItemSelected = (item: OptimizationAudience) => {
  if (!isItemDisabled(item.id)) {
    emits('listItemSelected', item)
  }
}
</script>
