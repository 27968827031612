import { TypeOf, array, boolean, number, object } from 'yup'
import { objValues } from '@lasso/shared/utils'
import { ChannelType, CpaGoalType, CpaMode, OptimizationGoalType } from '@lasso/api-activation/activation'
import { RefOrGetter } from '@lasso/shared/types'

import { computed, toValue } from 'vue'

import {
  WhenConditionCallback,
  extendSchema,
  msgCurrency,
  msgPercentage,
  oneOfEnum, typeOnlySchema,
} from '@lasso/shared/validation'

import { getIsEndemicChannel } from '../../../shared/channels'

import { getMediaBidMinMax } from '../../../shared/biddingAndOptimizations'

import { OptimizationAudience } from './types'

export const useCardBiddingAndOptimizationsSchema = ({
  isCardEnabled,
  whenChannelId,
}: {
  isCardEnabled: RefOrGetter<boolean>
  whenChannelId: WhenConditionCallback<ChannelType>
}) => {
  const baseShape = {
    mediaBid: number().nullable().default(null),
    viewability: number().nullable().default(null),
    crossDeviceExtension: boolean().default(false),
    tapAdCrossDeviceExtension: boolean().default(false),
    optimizationType: oneOfEnum([...objValues(OptimizationGoalType), null]).default(null),
    optimizationGoal: number().nullable().default(null),
    conversionType: oneOfEnum(CpaGoalType).default(CpaGoalType.POST_VIEW_AND_POST_CLICK),
    strategy: oneOfEnum(CpaMode).default(CpaMode.PROSPECTING),
    maintainCpc: number().nullable().default(null),
    conversions: array(typeOnlySchema<OptimizationAudience>()).default([]),
    conversionSegments: array(typeOnlySchema<OptimizationAudience>()).default([]),
    optimizationSegments: array(typeOnlySchema<OptimizationAudience>()).default([]),
  }

  const baseSchema = object(baseShape).required()

  const baseFlagsShape = {
    advancedOptimization: boolean().default(false),
    uniqueReachOptimization: boolean().default(false),
  }

  const baseFlagsSchema = object(baseFlagsShape).required()

  const whenOptimization: WhenConditionCallback<{
    advancedOptimization: boolean
    optimizationSelected: boolean
  }> = (schema, callback) => {
    return schema.when(
      ['optimizationFlags', 'biddingAndOptimizations'],
      (value1, value2) => {
        const { advancedOptimization = false } = (value1 ?? {}) as TypeOf<typeof baseFlagsSchema>
        const { optimizationType } = (value2 ?? {}) as TypeOf<typeof baseSchema>

        return callback({ advancedOptimization, optimizationSelected: Boolean(optimizationType) })
      },
    )
  }

  const cardBiddingSchema = computed((): typeof baseCardSchema => {
    const baseCardSchema = { biddingAndOptimizations: baseSchema, optimizationFlags: baseFlagsSchema }

    if (!toValue(isCardEnabled)) {
      return baseCardSchema
    }

    return {
      optimizationFlags: baseFlagsSchema,
      biddingAndOptimizations: whenChannelId(
        baseSchema,
        (channelId) => {
          const bidLimit = getMediaBidMinMax(toValue(channelId))

          if (channelId === ChannelType.BANNER) {
            return extendSchema(baseShape, {
              mediaBid: schema => schema.required().minMax(bidLimit.min, bidLimit.max, msgCurrency),
              viewability: schema => schema.minMax(1, 99, msgPercentage),
              optimizationGoal: schema => schema.when(
                'optimizationType',
                (optimizationType: OptimizationGoalType | null, schema) => {
                  if (optimizationType === null) {
                    return schema
                  }
                  else if (optimizationType === OptimizationGoalType.CTR) {
                    return schema.required()
                      .minMax(0.01, 100, msgPercentage)
                  }
                  else if ([OptimizationGoalType.CPC, OptimizationGoalType.CPA].includes(optimizationType)) {
                    return schema.required()
                      .min(0.01, msgCurrency)
                      .max(9999.99) // Do not use it for min-max message! It is a DB limitation, and also too huge value for this field, so we should show only min error in most cases.
                  }
                }),
              maintainCpc: schema => schema.when(
                ['optimizationType', 'conversionType'],
                {
                  is: (optimizationType: OptimizationGoalType, conversionType: CpaGoalType) =>
                    optimizationType === OptimizationGoalType.CPA && conversionType === CpaGoalType.POST_CLICK_ONLY,
                  then: schema => schema.required().min(0.01, msgCurrency),
                }),
              optimizationSegments: schema => schema.when('optimizationType', {
                is: OptimizationGoalType.CPA,
                then: schema => schema.required().min(1),
              }),
            })
          }
          else if (getIsEndemicChannel(channelId)) {
            return extendSchema(baseShape, {
              mediaBid: schema => schema.required().minMax(bidLimit.min, bidLimit.max, msgCurrency),
              viewability: schema => schema.minMax(1, 99, msgPercentage),
            })
          }
          else {
            return baseSchema
          }
        },
      ).required()
        .test(
          'hasMatchingSegments',
          (value, context) => {
            if (value.optimizationType !== OptimizationGoalType.CPA || value.optimizationSegments.length === 0) {
              return true
            }

            const hasMatchingSegments = value.conversionSegments.some((segment) => {
              return value.optimizationSegments.some((conversion) => {
                return conversion.id === segment.id
              })
            })

            return hasMatchingSegments || context.createError({
              path: 'biddingAndOptimizations.conversionSegments',
              message: 'Need matching conversion pixel for optimization pixel',
            })
          },
        ),
    }
  })

  return { whenOptimization, cardBiddingSchema }
}
