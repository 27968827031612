<template>
  <Card title="Bidding & Optimizations" opened collapse withDivider>
    <Skeleton v-if="loading" height="890px" />
    <ErrorState v-else-if="error" :error="error" @retry="request()" />
    <template v-else>
      <Box v-if="isEndemicChannel" flex direction="col" spaceY="6">
        <FormSection title="Add media bid">
          <FormControl
            :id="getId('biddingAndOptimizations.mediaBid')"
            v-slot="{ id }"
            label="Media bid"
            width="220px"
            required
          >
            <InputText
              :id="id"
              v-model="mediaBid"
              currency
              :mask="decimalMask"
            />
          </FormControl>
        </FormSection>

        <FormSection title="Add target viewability rate">
          <FormControl :id="getId('biddingAndOptimizations.viewability')" v-slot="{ id }" label="Viewability" width="220px">
            <InputText
              :id="id"
              v-model="viewability"
              endIcon="percent"
              :mask="numberMask"
            />
          </FormControl>
        </FormSection>

        <Box>
          <FieldControl
            :id="getId('optimizationFlags.advancedOptimization')"
            v-slot="{ id }"
            label="Add levers"
            variant="h6"
            position="left"
          >
            <Checkbox :id="id" v-model="advancedOptimization" />
          </FieldControl>
        </Box>
      </Box>
      <Box v-else flex direction="col" spaceY="6">
        <FormSection title="Add media bid">
          <FormControl
            :id="getId('biddingAndOptimizations.mediaBid')"
            v-slot="{ id }"
            label="Media bid"
            width="220px"
            required
          >
            <InputText
              :id="id"
              v-model="mediaBid"
              currency
              :mask="decimalMask"
            />
          </FormControl>
        </FormSection>

        <FormSection title="Add target viewability rate">
          <FormControl :id="getId('biddingAndOptimizations.viewability')" v-slot="{ id }" label="Viewability" width="220px">
            <InputText
              :id="id"
              v-model="viewability"
              endIcon="percent"
              :mask="numberMask"
            />
          </FormControl>
        </FormSection>
        <FormSection title="Select cross device graph">
          <Box flex direction="row" class="gap-6 overflow-x-auto">
            <RadioButton
              v-model="crossDeviceGraph"
              :disabled="isSourceAdGroup"
              value="xandr"
              title="Xandr"
            >
              <template #afterTitle>
                <Chip size="2xs" variant="outlined" label="$0.35" />
              </template>
            </RadioButton>
            <RadioButton
              v-model="crossDeviceGraph"
              :disabled="isSourceAdGroup"
              value="tapAd"
              title="TapAd"
            >
              <template #afterTitle>
                <Chip size="2xs" variant="outlined" label="$0.50" />
              </template>
            </RadioButton>
            <RadioButton
              v-model="crossDeviceGraph"
              :disabled="isSourceAdGroup"
              value="none"
              title="None"
            />
          </Box>
        </FormSection>

        <FormSection title="Add optimization">
          <Box flex spaceX="6" spaceY="2" wrap="wrap">
            <Box flex spaceX="6">
              <FormControl
                :id="getId('biddingAndOptimizations.optimizationType')"
                v-slot="{ id, error: fieldError }"
                width="220px"
                label="Optimization type"
              >
                <Select
                  :id="id"
                  v-model="optimizationTypeInternal"
                  :options="optimizationTypeOptions"
                  :error="fieldError"
                  width="160px" placeholder="Select"
                  cancelable
                />
              </FormControl>
              <FormControl
                v-if="optimizationType"
                :id="getId('biddingAndOptimizations.optimizationGoal')"
                v-slot="{ id }"
                width="220px"
                label="Optimization goal"
              >
                <InputText
                  :id="id"
                  v-model="optimizationGoal"
                  :currency="!isCtrOptimizationEnabled"
                  :endIcon="isCtrOptimizationEnabled ? 'percent' : ''"
                  :mask="decimalMask"
                />
              </FormControl>
            </Box>
            <template v-if="isCpaOptimizationEnabled">
              <FormControl :id="getId('biddingAndOptimizations.conversionType')" label="Conversion type" width="auto">
                <Box flex col spaceY="2">
                  <FieldControl id="postViewAndPostClick" v-slot="{ id }" label="Post-View and Post-Click" width="196px">
                    <Radio
                      :id="id" v-model="conversionType"
                      :value="CpaGoalType.POST_VIEW_AND_POST_CLICK" name="postViewAndPostClick"
                    />
                  </FieldControl>

                  <FieldControl id="postClick" v-slot="{ id }" label="Post-Click">
                    <Radio
                      :id="id" v-model="conversionType" :value="CpaGoalType.POST_CLICK_ONLY"
                      name="postClick"
                    />
                  </FieldControl>
                </Box>
              </FormControl>
              <FormControl
                v-if="isCpaAndPostViewAndPostClickEnabled"
                :id="getId('biddingAndOptimizations.strategy')"
                postClick
                label="Strategy"
                width="auto"
              >
                <Box flex col spaceY="2">
                  <FieldControl id="prospecting" v-slot="{ id }" label="Prospecting">
                    <Radio
                      :id="id" v-model="strategy" :value="CpaMode.PROSPECTING"
                      name="prospecting"
                    />
                  </FieldControl>

                  <FieldControl id="retargeting" v-slot="{ id }" label="Retargeting">
                    <Radio
                      :id="id" v-model="strategy" :value="CpaMode.RETARGETING"
                      name="retargeting"
                    />
                  </FieldControl>
                </Box>
              </FormControl>
              <FormControl
                v-if="isCpaAndPostClickOnlyEnabled"
                :id="getId('biddingAndOptimizations.maintainCpc')"
                v-slot="{ id }"
                width="220px"
                label="Maintain CPC value"
              >
                <InputText
                  :id="id"
                  v-model="maintainCpc"
                  currency
                  :mask="decimalMask"
                />
              </FormControl>
            </template>
          </Box>
          <FormError :id="getId('biddingAndOptimizations')" pt="2" />
        </FormSection>
        <FormSection
          v-if="isCpaOptimizationEnabled"
          title="Add conversion for CPA optimization"
        >
          <FormError :id="getId('biddingAndOptimizations.optimizationSegments')" pb="2" />
          <ConversionsSelector
            selectedItemsLabel="Selected Conversion"
            :listItems="conversions"
            :selectedListItems="optimizationSegments"
            :disableNotSelectedItems="optimizationSegments.length >= 1"
            @listItemSelected="handleOptimizationSegmentSelection"
            @removeSelectedItem="handleOptimizationSegmentRemove"
          />
        </FormSection>
        <FormSection title="Add conversion tracking">
          <FormError :id="getId('biddingAndOptimizations.conversionSegments')" pb="2" />
          <ConversionsSelector
            selectedItemsLabel="Selected Conversion Pixels"
            :listItems="conversions"
            :selectedListItems="conversionSegments"
            @listItemSelected="handleConversionSegmentSelection"
            @removeSelectedItem="handleConversionSegmentRemove"
          />
        </FormSection>

        <FormSection title="Add advanced optimization">
          <FormError :id="getId('optimizationFlags.uniqueReachOptimization')" pb="2" />
          <FormError :id="getId('optimizationFlags.advancedOptimization')" pb="2" />
          <Box flex direction="row" class="gap-6 overflow-x-auto">
            <Tooltip :content="advancedOptimizationTooltip">
              <RadioButton
                v-model="advancedOptimizationOption"
                :disabled="endemicBlendEnabled"
                value="uniqueReach"
                title="Unique Reach"
              >
                <template v-if="endemicBlendEnabled" #afterTitle>
                  <Icon icon="info" color="textSecondaryLight" variant="outlined" size="xs" />
                </template>
              </RadioButton>
            </Tooltip>
            <Tooltip :content="advancedOptimizationTooltip">
              <RadioButton
                v-model="advancedOptimizationOption"
                :disabled="endemicBlendEnabled"
                value="levers"
                title="Levers"
              >
                <template v-if="endemicBlendEnabled" #afterTitle>
                  <Icon icon="info" color="textSecondaryLight" variant="outlined" size="xs" />
                </template>
              </RadioButton>
            </Tooltip>
            <RadioButton
              v-model="advancedOptimizationOption"
              value="none"
              title="None"
            />
          </Box>
        </FormSection>
      </Box>
    </template>
  </Card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import {
  Box,
  Card, Checkbox,
  Chip,
  ErrorState,
  FieldControl,
  FormControl, FormError,
  FormSection, Icon,
  InputText,
  Radio,
  RadioButton,
  Select,
  Skeleton,
  Tooltip,
} from '@lasso/luikit'
import { CpaGoalType, CpaMode, OptimizationGoalType } from '@lasso/api-activation/activation'
import { decimalMask, numberMask } from '@lasso/shared/consts'

import ConversionsSelector from './ConversionsSelector.vue'
import { useCardBiddingAndOptimizations } from './useCardBiddingAndOptimizations'

type CrossDeviceGraph = 'xandr' | 'tapAd' | 'none'
type AdvancedOptimization = 'uniqueReach' | 'levers' | 'none'

const {
  loading,
  error,
  request,

  isEndemicChannel,
  endemicBlendEnabled,
  isSourceAdGroup,

  mediaBid,
  viewability,
  crossDeviceExtension,
  tapAdCrossDeviceExtension,
  optimizationType,
  optimizationGoal,
  conversionType,
  strategy,
  maintainCpc,
  conversions,
  conversionSegments,
  optimizationSegments,
  uniqueReachOptimization,
  advancedOptimization,

  isCtrOptimizationEnabled,
  isCpaOptimizationEnabled,
  isCpaAndPostClickOnlyEnabled,
  isCpaAndPostViewAndPostClickEnabled,

  handleConversionSegmentSelection,
  handleOptimizationSegmentSelection,
  handleConversionSegmentRemove,
  handleOptimizationSegmentRemove,

  getId,
} = useCardBiddingAndOptimizations()!

const crossDeviceGraph = computed({
  get(): CrossDeviceGraph {
    if (crossDeviceExtension.value) {
      return 'xandr'
    }
    if (tapAdCrossDeviceExtension.value) {
      return 'tapAd'
    }
    return 'none'
  },
  set(value) {
    if (value === 'xandr') {
      crossDeviceExtension.value = true
      tapAdCrossDeviceExtension.value = false
    }
    else if (value === 'tapAd') {
      crossDeviceExtension.value = false
      tapAdCrossDeviceExtension.value = true
    }
    else if (value === 'none') {
      crossDeviceExtension.value = false
      tapAdCrossDeviceExtension.value = false
    }
  },
})

const advancedOptimizationOption = computed({
  get(): AdvancedOptimization {
    if (uniqueReachOptimization.value) {
      return 'uniqueReach'
    }
    if (advancedOptimization.value) {
      return 'levers'
    }
    return 'none'
  },
  set(value) {
    if (value === 'uniqueReach') {
      uniqueReachOptimization.value = true
      advancedOptimization.value = false
    }
    else if (value === 'levers') {
      uniqueReachOptimization.value = false
      advancedOptimization.value = true
    }
    else if (value === 'none') {
      uniqueReachOptimization.value = false
      advancedOptimization.value = false
    }
  },
})

const advancedOptimizationTooltip = computed(() => {
  return endemicBlendEnabled.value ? 'Advanced optimization cannot be enabled when Endemic Blend is enabled' : ''
})

const optimizationTypeOptions = [
  { label: 'CTR', value: OptimizationGoalType.CTR },
  { label: 'CPC', value: OptimizationGoalType.CPC },
  { label: 'CPA', value: OptimizationGoalType.CPA },
]

const optimizationTypeInternal = computed({
  get: () => optimizationType.value ?? '',
  set: (value) => {
    optimizationType.value = value || null
  },
})
</script>
