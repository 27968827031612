import { useApiManual } from '@lasso/shared/hooks'

import {
  ChannelType,
  CpaGoalType,
  CpaMode,
  OptimizationGoalType, useActivationApi,
} from '@lasso/api-activation/activation'

import { getIsEndemicChannel } from '../../../shared/channels'

import { BiddingAndOptimizationsFormOutput } from './types'

export const useCardBiddingAndOptimizationsSubmit = () => {
  const { requestThrows: setMediaBidStrategyInternal } = useApiManual(useActivationApi().setMediaBidStrategy)

  const setMediaBidStrategy = async ({
    adGroupId,
    channelId,
    mediaBid,
    viewability,
    crossDeviceExtension,
    tapAdCrossDeviceExtension,
    optimizationGoal,
    optimizationType,
    conversionType,
    maintainCpc,
    conversionSegments,
    optimizationSegments,
    strategy,
  }: {
    adGroupId: number
    channelId: ChannelType
  } & BiddingAndOptimizationsFormOutput) => {
    const isCpaOptimizationEnabled = optimizationType === OptimizationGoalType.CPA
    const isCpaAndPostClickOnlyEnabled = isCpaOptimizationEnabled && conversionType === CpaGoalType.POST_CLICK_ONLY
    const isCpaAndPostViewAndPostClickEnabled = isCpaOptimizationEnabled && conversionType === CpaGoalType.POST_VIEW_AND_POST_CLICK

    let cpaModeId

    if (isCpaAndPostClickOnlyEnabled) {
      cpaModeId = CpaMode.PROSPECTING
    }
    else if (isCpaAndPostViewAndPostClickEnabled) {
      cpaModeId = strategy
    }

    const values = getIsEndemicChannel(channelId)
      ? {
          adgroupId: adGroupId,
          mediaBid: mediaBid ?? undefined,
          viewability: viewability ?? undefined,
        }
      : {
          adgroupId: adGroupId,
          mediaBid: mediaBid ?? undefined,
          viewability: viewability ?? undefined,
          crossDeviceExtension,
          tapAdCrossDeviceExtension,
          optimizationGoal: optimizationType ? optimizationGoal ?? undefined : undefined,
          optimizationGoalType: optimizationType ?? undefined,
          cpaModeId,
          cpaGoalTypeId: isCpaOptimizationEnabled ? conversionType ?? undefined : undefined,
          maintainCpc: isCpaAndPostClickOnlyEnabled ? maintainCpc! : undefined,
          conversionSegments: conversionSegments.map(it => it.id),
          optimizationSegments: optimizationType === OptimizationGoalType.CPA ? optimizationSegments.map(it => it.id) : [],
        }

    await setMediaBidStrategyInternal(values)
  }

  return { setMediaBidStrategy }
}
