import { CpaGoalType, CpaMode, OptimizationGoalType } from '@lasso/api-activation/activation'

import { BiddingAndOptimizationsFormInput } from './types'

export const isRetargeting = ({
  optimizationType,
  conversionType,
  strategy,
}: Pick<BiddingAndOptimizationsFormInput, 'optimizationType' | 'conversionType' | 'strategy'>) => {
  return optimizationType === OptimizationGoalType.CPA
    && conversionType === CpaGoalType.POST_VIEW_AND_POST_CLICK
    && strategy === CpaMode.RETARGETING
}
